import { Close, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Select,
  MenuItem,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import PropTypes from "prop-types";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { getSingleAttribute } from "redux/festures/AttributeSlice";
import { getSingleBlog } from "redux/festures/blogSlice";
import { updateBlog } from "redux/festures/blogSlice";
import { getAllBlogs } from "redux/festures/blogSlice";
import { formattedDateServer } from "Utils/dateFunc";
// import Form from "./Form";
// import SingleBlog from "./SingleBlog";
import { getGlobalBlog } from "redux/festures/blogSlice";
import MDInput from "components/MDInput";
import View from "./View";
import { useMaterialUIController } from "context";
import { getAllContacts } from "redux/festures/contactusSlice";

const columns = {
  AllBlogs: [
    { Header: "S.No", accessor: "no" },
    { Header: "details", accessor: "details" },
    { Header: "email/Mobile", accessor: "email/Mobile" },
    { Header: "date", accessor: "date" },
    { Header: "view", accessor: "view" },
  ],
};
const ContactUs = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);

  const { isLoading, allContacts, isPages } = useSelector((data) => ({ ...data?.isContactUs }));

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isSearch, setIsSearch] = useState("");
  const [isFilterName, setIsFilterName] = useState("name");
  const [pagess, setPagess] = useState(1);

  useEffect(() => {
    dispatch(getAllContacts(`/getAllContactUs/${admin}?page=${pagess}`));
  }, [pagess]);
  //   console.log(blogs, "blogs");
  useEffect(() => {
    if (allContacts && allContacts.length > 0) {
      const temprows =
        allContacts &&
        allContacts?.at(0) &&
        allContacts?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          details: (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 0.5,
              }}
            >
              {/* <MDBox sx={{ height: 40, width: 40 }}>
                <img
                  src={`${process.env.REACT_APP_URI}/${value?.image}`}
                  alt={"img"}
                  onError={(e) => {
                    (e.onError = null),
                      (e.target.src = require("../../assets/images/bg-profile.png"));
                  }}
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                />
              </MDBox> */}
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 0.5,
                  flexDirection: "column",
                }}
              >
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  style={{
                    maxWidth: "250px",
                    lineHeight: "20px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {value?.fullName || "N/A"}
                </MDTypography>
              </MDBox>
            </MDBox>
          ),
          "email/Mobile": (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12}>Email : {value?.email || "N/A"}</MDTypography>
              <MDTypography fontSize={12}>Mobile : {value?.mobile || "N/A"}</MDTypography>
            </MDBox>
          ),
          date: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12}>
                {formattedDateServer(new Date(value?.createdAt))}
              </MDTypography>
            </MDBox>
          ),
          view: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpen(true);
                setIsOpenUpdate(value);
              }}
            >
              <Visibility
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),
        }));
      setRowsData(temprows);
    } else {
      setRowsData(["", " "]);
    }
  }, [allContacts]);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                ContactUs Table{" "}
              </MDTypography>
            </MDBox>

            <MDBox py={3}>
              {isLoading ? (
                <SkLoading />
              ) : allContacts && allContacts.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: columns?.AllBlogs,
                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={allContacts && allContacts.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />{" "}
                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={isPages}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h5"> Contect Not Found ...! </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        maxHeight={"90vh"}
        padding={3}
        overflowY={true}
      >
        <View isOpenUpdate={isOpenUpdate} setIsOpenUpdate={setIsOpenUpdate} setIsOpen={setIsOpen} />
      </SkModal>
    </>
  );
};

export default ContactUs;
