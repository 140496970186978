import http from "Utils/api2";
import axios from "axios";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getSingleFAQ = createAsyncThunk("getSingleFAQ", async (data) => {
  try {
    // const res = await http.get(url);
    // return res.data;
    return data;
  } catch (error) {
    return { data: null };
  }
});

export const updateFAQ = createAsyncThunk("updateFAQ", async ({ url, data }) => {
  try {
    const res = await axios.put(url, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
        // "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const createFAQ = createAsyncThunk("createFAQ", async ({ url, data }) => {
  try {
    const res = await axios.post(url, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
        // "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const deleteFAQ = createAsyncThunk("deleteFAQ", async (url) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const getAllFAQ = createAsyncThunk("getAllFAQ", async (url) => {
  try {
    const res = await http.get(url);

    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getGlobalAllFAQ = createAsyncThunk("getGlobalAllFAQ", async (url) => {
  try {
    const res = await http.get(url);

    return res.data;
  } catch (error) {
    return { data: null };
  }
});
const faqSlice = createSlice({
  name: "faqSlice",
  initialState: {
    isLoading: false,
    allFAQ: null,
    singleFAQ: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllFAQ.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllFAQ.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allFAQ = action.payload?.data;
        // console.log(action.payload?.data);
      })
      .addCase(getGlobalAllFAQ.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allFAQ = action.payload?.data;
        // console.log(action.payload?.data);
      })
      .addCase(getAllFAQ.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getSingleFAQ.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSingleFAQ.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleFAQ = action.payload?.data;
        // console.log(action.payload?.data);
      })
      .addCase(getSingleFAQ.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default faqSlice.reducer;
