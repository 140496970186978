import { Cancel } from "@mui/icons-material";
import { Card, CircularProgress } from "@mui/material";
import ApnaSelect2 from "components/ApnaSelect";
import ImagePicker from "components/ApnaUploader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import AstrieskIcon from "components/AstrieskIcon";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import { updateCategory } from "redux/festures/categorySlice";
import { handleAlert } from "redux/festures/alertSlice";
import { getGlobalCategory } from "redux/festures/categorySlice";
import { creatCategoryData } from "redux/festures/categorySlice";
import { getCategory } from "redux/festures/categorySlice";

const SubcategoryUpdate = ({ viewData,isUpdates,setIsOpenUpdate2,filter }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();

  const admin = localStorage.getItem("admin_id");


  const {
    Loading,
    category,
    allCategory,
    subCategory,
    IsLoading,
    isPages,
    subCategoryData,
    createUpdateLoading,
  } = useSelector((state) => ({
    ...state.isCategory,
  }));

 

  useEffect(() => {
    dispatch(getCategory(`/eCommerce/getAllNullPcategory/${admin}`));
  }, []);


  const [profile, setProfile] = useState({
    categoryName: "",
    uploadPic: "",
    bannerImage: "",
    bannerVideo: "",
    cityId: [],
    categoryId: "",
    category__Id: "",
    isShow: "",
    serverBannerImage: null,
  });

  useEffect(() => {
    if (viewData && isUpdates) {
      setProfile({
        categoryName: viewData?.name || "",
        uploadPic: "",
        bannerImage: "",
        bannerVideo: "",
        // cityId:
        //   city && city?.length && viewData?.cityId?.length
        //     ? city?.filter((ele) => viewData?.cityId?.includes(ele?._id))
        //     : [],
        categoryId: viewData?.pCategory || "",
        category__Id: viewData?._id || "",
        isShow: viewData?.icon || "",
        serverBannerImage: viewData?.banner?.length
          ? viewData?.banner?.filter((ele) => ele?.type === "IMAGE")
          : null,
      });
    } else {
      setProfile({
        categoryName: "",
        uploadPic: "",
        bannerImage: "",
        bannerVideo: "",
        cityId: [],
        categoryId: "",
        category__Id: "",
        isShow: "",
        serverBannerImage: null,
      });
    }
  }, [viewData, isUpdates]);



  const handleSubmitUpdateSubCategory = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", profile.categoryName);
    if (profile?.uploadPic) formData.append("icon", profile.uploadPic);
    formData.append("pCategory", profile.categoryId);

    if (profile?.bannerImage && profile?.bannerImage?.length)
      profile?.bannerImage?.map((e) => formData.append("banner", e));

    dispatch(
      isUpdates
        ? updateCategory({
            url: `/eCommerce/updateCategory/${profile?.categoryId}/${admin}`,
            data: formData,
          })
        : creatCategoryData({
            url: `/eCommerce/createCategoryss/${admin}`,
            reqBody: formData,
          })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        setProfile({
          categoryName: "",
          uploadPic: "",
          bannerImage: "",
          bannerVideo: "",
          cityId: [],
          categoryId: "",
          category__Id: "",
          isShow: "",
          serverBannerImage: null,
        });
        // setFile();
        dispatch(getGlobalCategory(`eCommerce/getAllCategoryWithPcategory/${admin}?disable=${
          filter === false ? false : filter || ""
        }`));
        // setIsOpen(false);
        // setIsOpen2(false);
        // setIsOpenUpdate(false);
        setIsOpenUpdate2(false);
      }
    });
 
  };

  return (
    <>


<MDBox
    display="flex"
    alignItems="center"
    lineHeight={1}
    sx={({ palette: { dark, white, info } }) => ({
      border: 0.5,
      borderColor: darkMode ? white.main : dark.main,
      borderRadius: 3,
      p: 2,
      width: "100%",
      //   height: "70vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: 2,
    })}
  >
    <Card
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 , 10px",
      }}
    >
      <MDTypography variant="h6" py={0.9}>
      {isUpdates?" Update Sub Category":"Create Sub Category"}
      </MDTypography>
    </Card>
    <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 5,
          width: "100%",
        }}
        component="form"
        role="form"
        onSubmit={handleSubmitUpdateSubCategory}
      >
      
        <MDBox
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          lineHeight={1}
          justifyContent="flex-start"
          gap={3}
          width={"75%"}
        >
          <MDTypography variant="h6">Select Parent Category</MDTypography>
        
          <ApnaSelect2
            disabled={createUpdateLoading}
            data={allCategory}
            origin="Category"
            value={profile?.categoryId}
            name="categoryId"
            onChange={(e) =>
              setProfile((prev) => ({
                ...prev,
                categoryId: e.target.value,
              }))
            }
            nameKey="name"
            valueKey="_id"
          />
        </MDBox>
        <MDBox
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          justifyContent="flex-start"
          lineHeight={1}
          gap={3}
          width={"75%"}
        >
          <MDTypography variant="h6">
            Category Name
            <AstrieskIcon />
          </MDTypography>
          <MDInput
            disabled={createUpdateLoading}
            required={true}
            type="text"
            placeholder="category name"
            fullWidth
            pl={3}
            value={profile.categoryName}
            onChange={(e) => {
              setProfile((prev) => ({
                ...prev,
                categoryName: e.target.value,
              }));
            }}
          />
        </MDBox>
    
        <MDBox
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          lineHeight={1}
          gap={3}
          width={"75%"}
        >
          <MDTypography variant="h6">
            Category Image{" "}
            <MDTypography variant="body1" component="span" fontSize={11}>
              &nbsp; ( image size - 178 × 178 px )
            </MDTypography>
          </MDTypography>
          <ImagePicker
            disabled={createUpdateLoading}
            // required={true}
            name="Image"
            multiple={false}
            images={profile?.uploadPic}
            setImages={(e) => {
              setProfile((prev) => ({
                ...prev,
                uploadPic: e,
              }));
            }}
            //
          />
          {profile?.uploadPic === "" && profile?.isShow && (
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <span
                style={{
                  display: "inline-block",
                  width: "50px",
                  height: "50px",
                  margin: "0 0.5rem",
                }}
              >
                <img
                  className="Image"
                  style={{ width: "100%", height: "100%" }}
                  src={`${process.env.REACT_APP_URI}/${profile?.isShow}`}
                />
              </span>
              <span
                className="cross"
                style={{
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setProfile((prev) => ({
                    ...prev,
                    uploadPic: "",
                  }));
                }}
              >
                <Cancel
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? white?.main : dark.main,
                  })}
                />
              </span>
            </div>
          )}
        </MDBox>
        <MDBox
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          lineHeight={1}
          gap={3}
          width={"75%"}
        >
          <MDTypography variant="h6">
            Category Banner Image{" "}
            <MDTypography variant="body1" component="span" fontSize={11}>
              &nbsp; ( image size - 178 × 178 px )
            </MDTypography>
          </MDTypography>
          <ImagePicker
            disabled={createUpdateLoading}
     
            name="bannerImage"
            multiple={true}
            images={profile?.bannerImage}
            setImages={(e) => {
              setProfile((prev) => ({
                ...prev,
                bannerImage: e,
              }));
            }}
            
          />
          {profile?.serverBannerImage && profile?.serverBannerImage?.length ? (
            <div style={{ display: "flex", alignItems: "flex-start", gap: "0.5rem" }}>
              {profile?.serverBannerImage?.map((ele, i) => (
                <span style={{ display: "flex", alignItems: "flex-start" }} key={i}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "50px",
                      height: "50px",
                      margin: "0 0.5rem",
                    }}
                  >
                    <img
                      className="Image"
                      style={{ width: "100%", height: "100%" }}
                      src={`${process.env.REACT_APP_URI}/${ele?.url}`}
                    />
                  </span>
                  <span
                    className="cross"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      dispatch(
                        unlinkImage({
                          url: `/unLinks/${profile?.categoryId}/${admin}`,
                          data: { imageIndex: i + 1 },
                          notFormData: true,
                        })
                      ).then((data) => {
                        if (data?.payload?.success) {
                          const temp = [...profile?.serverBannerImage];
                          temp.splice(i, 1);
                          setProfile((prev) => ({
                            ...prev,
                            serverBannerImage: temp,
                          }));
                          dispatch(
                            handleAlert({
                              isOpen: true,
                              type: `${data.payload.success ? "success" : "error"}`,
                              msg: data.payload.message,
                            })
                          );
                        }
                      });
                    }}
                  >
                    <Cancel
                      sx={({ palette: { dark, white, info } }) => ({
                        color: darkMode ? white?.main : dark.main,
                      })}
                    />
                  </span>
                </span>
              ))}
            </div>
          ) : null}
        </MDBox>
   
        <MDBox
          sx={{ width: "75%", justifyContent: "flex-end", textAlign: "center", display: "flex" }}
        >
          {" "}
          <MDButton color={"info"} verdant={"gradient"} type={"submit"}>
            {createUpdateLoading ? (
              <CircularProgress color="primary" size={20} />
            ) : (isUpdates?
              `Update Sub Category`:"Create Sub Category"
            )}
          </MDButton>
        </MDBox>
      </MDBox>


    </MDBox>
    
    </>
  );
};

export default SubcategoryUpdate;

SubcategoryUpdate.propTypes = {
  viewData: PropTypes.any,
  isUpdates: PropTypes.bool,
  filter: PropTypes.bool,
  setIsOpenUpdate2: PropTypes.func,
};
