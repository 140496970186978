import { Close, Delete, Edit, ExpandMore, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Tooltip,
  AccordionDetails,
  Input,
  AccordionSummary,
  Accordion,
} from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
import Form from "./Form";
import { getAllTax, getSingleTax } from "redux/festures/taxSlice";
import { getAllFAQ } from "redux/festures/faqSlice";
import { getGlobalAllFAQ } from "redux/festures/faqSlice";
import { deleteFAQ } from "redux/festures/faqSlice";
import { getSingleFAQ } from "redux/festures/faqSlice";
import SkConfirm from "components/SkComfirm";

// import Form from "./Form";
// import SingleBlog from "./SingleBlog";
const columns = {
  AllTax: [
    { Header: "S.No", accessor: "no" },
    { Header: "Tax Percent", accessor: "Tax Percent" },
    { Header: "action", accessor: "action" },
  ],
};
const FAQ = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  // const [isSwitch, setIsSwitch] = useState(null);

  const [isOpenDialog, setIsOpenDialog] = useState({
    open: false,
    isId: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const { isLoading, allFAQ, singleFAQ } = useSelector((state) => ({ ...state?.isFAQ }));

  const [isId, setIsId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(getAllFAQ(`${process.env.REACT_APP_APII}/getAllFAQ`));
  }, []);

  // useEffect(() => {
  //   if (allFAQ && allFAQ.length > 0) {
  //     const temprows =
  //       allFAQ &&
  //       allFAQ?.at(0) &&
  //       allFAQ?.map((value, index) => ({
  //         no: (
  //           <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
  //             {index + 1}
  //           </MDTypography>
  //         ),
  //         "Tax Percent": (
  //           <MDBox flexDirection="column">
  //             <MDTypography fontSize={12.5}>
  //               {/* {console.log(value?.taxPercent)} */}
  //               {value?.taxPercent || "N/A"}%
  //             </MDTypography>
  //           </MDBox>
  //         ),
  //         action: (
  //           <>
  //             <IconButton
  //               aria-label="action_edit"
  //               onClick={() => {
  //                 setIsOpen(true);
  //                 setIsOpenUpdate(true);
  //                 // console.log(value?._id);
  //                 dispatch(getSingleFAQ(`/getByFAQId/${value?._id}/${admin}`));
  //               }}
  //             >
  //               <Edit
  //                 sx={({ palette: { dark, white, info } }) => ({
  //                   color: darkMode ? info.main : dark.main,
  //                 })}
  //               />
  //             </IconButton>
  //           </>
  //         ),
  //       }));
  //     setRowsData(temprows);
  //   } else {
  //     setRowsData(["", " "]);
  //   }
  // }, [isSwitch]);

  const handleBinSwitch = (value) => {
    dispatch(deleteFAQ(`${process.env.REACT_APP_APII}/deleteFAQ/${value}/${admin}`)).then(
      (data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        if (data?.payload?.success) {
          // setIsSwitch(!isSwitch);
          dispatch(getGlobalAllFAQ(`${process.env.REACT_APP_APII}/getAllFAQ`));
        }
      }
    );
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                FAQ&apos;s Table{" "}
              </MDTypography>
              <MDButton
                variant="gradient"
                color="dark"
                sx={({ palette: { dark, white, info } }) => ({
                  color: white.main,
                  backgroundColor: dark.main,
                  "&:hover": {
                    color: white.main,
                    backgroundColor: dark.main,
                  },
                })}
                onClick={() => {
                  setIsOpen(true);
                  setIsOpenUpdate(false);
                  setIsOpenView(null);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp; Create FAQ
              </MDButton>
            </MDBox>
            <MDBox pt={3} width={"100%"}>
              {isLoading ? (
                <SkLoading />
              ) : (
                allFAQ &&
                allFAQ?.length > 0 &&
                allFAQ.map((value, index) => (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    onClick={() => {
                      setIsId(value?._id);
                    }}
                    py={1.5}
                    px={3}
                    sx={{
                      "&.MuiPaper-root": {
                        backgroundColor: "transparent.main",
                      },
                    }}
                    style={{
                      "&.MuiPaper-root": {
                        backgroundColor: "transparent",
                        width: "100% !important",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMore
                          sx={({ palette: { dark, white, info } }) => ({
                            color: darkMode ? white.main : info.main,
                            textAlign: "left",
                            mr: 2,
                          })}
                        />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row-reverse",
                        width: "100%",
                      }}
                    >
                      <MDBox
                        id="shubham"
                        sx={({ breakpoints }) => ({
                          display: "flex",
                          alignItems: "space-between",
                          justifyContent: "space-between",
                          // gap: 1,
                          flexGrow: 1,
                          width: "100%",
                          // flexDirection: { sx: "column", sm: "column", md: "row", xl: "row" },

                          [breakpoints.up("xs")]: {
                            flexDirection: "column",

                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            // gap: 1,
                          },
                          [breakpoints.up("md")]: {
                            flexDirection: "row",

                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            // gap: 1,
                          },
                        })}
                      >
                        <MDBox display="flex" alignItems="center" sx={{ mr: 1, width: "100%" }}>
                          <MDTypography
                            display="block"
                            variant="button"
                            fontWeight="medium"
                            ml={1}
                            style={{
                              maxWidth: "100%",
                              lineHeight: "20px",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {value?.question}
                          </MDTypography>
                        </MDBox>

                        <MDBox
                          display="flex"
                          alignItems="center"
                          lineHeight={1}
                          zIndex={10}
                          sx={{ width: "20%" }}
                        >
                          <Tooltip title={value?.disable ? "move to Active" : "delete"}>
                            <IconButton
                              aria-label="action_edit"
                              // disabled={value?.disable}
                              onClick={() => {
                                // handleBinSwitch(value?._id);

                                setIsOpenDialog((Preview) => ({
                                  ...Preview,
                                  open: true,
                                  isId: value?._id,
                                }));
                              }}
                            >
                              <Delete
                                sx={({ palette: { dark, white, info } }) => ({
                                  color: darkMode ? info.main : dark.main,
                                })}
                              />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                        <MDBox
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            // flexDirection: { sx: "column", xs: "column", md: "row", xl: "row" },
                            alignItems: "center",
                            gap: 2,
                            width: "20%",
                          }}
                        >
                          <MDTypography
                            display="block"
                            variant="button"
                            fontWeight="medium"
                            ml={1}
                            lineHeight={1}
                          >
                            Edit
                          </MDTypography>
                          <IconButton
                            aria-label="action_edit"
                            onClick={() => {
                              setIsOpen(true);
                              setIsOpenUpdate(true);
                              setIsOpenView(value);
                              // console.log(value?._id);
                            }}
                          >
                            <Edit
                              sx={({ palette: { dark, white, info } }) => ({
                                color: darkMode ? info.main : dark.main,
                              })}
                            />
                          </IconButton>
                        </MDBox>
                      </MDBox>
                    </AccordionSummary>
                    <AccordionDetails>
                      {isLoading ? (
                        <SkLoading />
                      ) : expanded === `panel${index}` ? (
                        //   && subCategory && subCategory?.at(0)
                        <MDBox sx={{ textAlign: "flex-start", ml: 3 }}>
                          <MDTypography variant="button" fontWeight="medium" color="white">
                            {value?.answer}
                          </MDTypography>
                        </MDBox>
                      ) : (
                        <MDTypography
                          display="block"
                          variant="button"
                          fontWeight="medium"
                          ml={1}
                          lineHeight={1}
                        >
                          There are no FAQ ...!
                        </MDTypography>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <Form
          isOpenUpdate={isOpenUpdate}
          setIsOpenUpdate={setIsOpenUpdate}
          setIsOpen={setIsOpen}
          setIsOpenView={setIsOpenView}
          isOpenView={isOpenView}
        />
      </SkModal>

      <SkConfirm
        dialogTitle={"Delete"}
        dialogContent={"Are you sure you want to delete this category?"}
        open={isOpenDialog}
        setOpen={setIsOpenDialog}
        dialogAction={
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3,

              px: 3,
            }}
          >
            <MDButton
              variant="gradient"
              color="info"
              sx={{ width: "50%" }}
              onClick={() => {
                setIsOpenDialog((Preview) => ({
                  ...Preview,
                  open: false,
                  isId: null,
                }));
              }}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              sx={{ width: "50%" }}
              color="error"
              onClick={() => {
                dispatch(
                  deleteFAQ(`${process.env.REACT_APP_API}/deleteFAQ/${isOpenDialog?.isId}/${admin}`)
                ).then((data) => {
                  dispatch(
                    handleAlert({
                      isOpen: true,
                      type: `${data?.payload?.success ? "success" : "error"}`,
                      msg: data?.payload?.message,
                    })
                  );
                  if (data?.payload?.success) {
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: `${data?.payload?.success ? "success" : "error"}`,
                        msg: data?.payload?.message,
                      })
                    );

                    // setIsSwitch(!isSwitch);
                    dispatch(getGlobalAllFAQ(`${process.env.REACT_APP_APII}/getAllFAQ`));

                    setIsOpenDialog((Preview) => ({
                      ...Preview,
                      open: false,
                    }));
                  }
                  // setIsId(isOpenDialog?.isId);
                });
              }}
            >
              yes
            </MDButton>
          </MDBox>
        }
      />
    </>
  );
};

export default FAQ;
