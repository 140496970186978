import http from "Utils/api2";
import axios from "axios";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllContacts = createAsyncThunk("getAllContacts", async (url) => {
  try {
    const res = await http.get(url);

    return res.data;
  } catch (error) {
    return { data: null };
  }
});
const contactUsSlice = createSlice({
  name: "contactUs",
  initialState: {
    isLoading: false,
    allContacts: null,
    isPages: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllContacts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllContacts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allContacts = action.payload?.data;
        state.isPages = action.payload?.page;
        // console.log(action.payload?.data);
      })

      .addCase(getAllContacts.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default contactUsSlice.reducer;
