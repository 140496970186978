import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardMedia,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  Switch,
  Tab,
  Typography,
  IconButton,
  Avatar,
  Stack,
  Button,
  OutlinedInput,
  ListItemText,
  MenuItem,
  Checkbox,
  Select,
  TextField,
  Tooltip,
  Pagination,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Header from "layouts/profile/components/Header";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "redux/festures/categorySlice";
import MDAvatar from "components/MDAvatar";
import { getSubCategory } from "redux/festures/categorySlice";
import SkLoading from "components/SkLoading";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Cancel,
  Close,
  Delete,
  Edit,
  ExpandMore,
  Input,
  PhotoCamera,
  Visibility,
  WifiTetheringTwoTone,
} from "@mui/icons-material";
import SkModal from "components/SkModal";
import MDInput from "components/MDInput";
import { creatCategoryData } from "redux/festures/categorySlice";
import { deleteCategory } from "redux/festures/categorySlice";
import { handleAlert } from "redux/festures/alertSlice";
import { updateCategory } from "redux/festures/categorySlice";
import ImagePicker from "components/ApnaUploader";
import { getGlobalCategory } from "redux/festures/categorySlice";
import { getSubGlobalCategory } from "redux/festures/categorySlice";
import SkConfirm from "components/SkComfirm";
import ApnaSelect from "components/ApnaSelect/ApnaSelect";
import { getAllCity } from "redux/festures/citySlice";
import { useLocation } from "react-router-dom";
import ApnaSelect2 from "components/ApnaSelect";
import { unlinkImage } from "redux/festures/categorySlice";
import AstrieskIcon from "components/AstrieskIcon";
import SubcategoryUpdate from "./SubcategoryUpdate";
import SingleCategory from "./SingleCategory";
import PcatogeroyUpdate from "./PcatogeroyUpdate";

const disableFilter = [
  {
    _id: 0,
    name: "All",
  },
  {
    _id: true,
    name: "Disable",
  },
  {
    _id: false,
    name: "Active",
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Category = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const {
    Loading,
    category,
    subCategory,
    IsLoading,
    isPages,
    subCategoryData,
    createUpdateLoading,
  } = useSelector((state) => ({
    ...state.isCategory,
  }));
  const { city } = useSelector((data) => ({ ...data?.isCity }));
  const [ecom, setEcom] = useState(false);

  // console.log(category, "category");

  const [rowsData, setRowsData] = useState([]);
  const [rowsSubData, setRowssubData] = useState([]);
  const [isId, setIsId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenUpdate2, setIsOpenUpdate2] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [isUpdates, setIsUpdate] = useState(false);
  const [isPUpdates, setIsPUpdate] = useState(false);

  const [isSwitch, setIsSwitch] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState({ open: false, isId: null });
  const [filter, setFilter] = useState(false);
  const [pagess, setPagess] = useState(1);
  const [isValue, setIsValue] = useState(null);

  useEffect(() => {
    dispatch(
      getGlobalCategory(
        `eCommerce/getAllCategoryWithPcategory/${admin}?disable=${
          filter === false ? false : filter || ""
        }&page=${pagess || ""}`
      )
    );
  }, [filter, pagess]);

  //   useEffect(() => {
  //     if (category && isId) {
  //       const temprows =
  //         category?.at(0)?.subCategory &&
  //         category?.at(0)?.subCategory?.map((value, index) => ({
  //           no: (
  //             <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
  //               {index + 1}
  //             </MDTypography>
  //           ),
  //           name: (
  //             <MDTypography
  //               display="block"
  //               variant="button"
  //               fontWeight="medium"
  //               sx={{
  //                 "&.css-13nwy6q-MuiTypography-root": {
  //                   textTransform: "capitalize!important",
  //                 },
  //               }}
  //               ml={1}
  //               lineHeight={1}
  //               style={{
  //                 maxWidth: "250px",
  //                 lineHeight: "20px",
  //                 display: "-webkit-box",
  //                 WebkitBoxOrient: "vertical",
  //                 WebkitLineClamp: 2,
  //                 overflow: "hidden",
  //                 textOverflow: "ellipsis",
  //               }}
  //             >
  //               {value?.name}
  //             </MDTypography>
  //           ),
  //           image: (
  //             <MDBox sx={{ height: 40, width: 40 }}>
  //               <img
  //                 src={`${process.env.REACT_APP_URI}/${value?.icon}`}
  //                 alt={"img"}
  //                 onError={(e) => {
  //                   (e.onError = null),
  //                     (e.target.src = require("../../assets/images/bg-profile.png"));
  //                 }}
  //                 style={{ width: "100%", height: "100%", borderRadius: "50%" }}
  //               />
  //             </MDBox>
  //           ),
  //           view: (
  //             <IconButton
  //               aria-label="action_edit"
  //               onClick={() => {
  //                 setViewData(value);
  //                 setIsOpenView(true);
  //               }}
  //             >
  //               <Visibility
  //                 sx={({ palette: { dark, white, info } }) => ({
  //                   color: darkMode ? info.main : dark.main,
  //                 })}
  //               />
  //             </IconButton>
  //           ),

  //           delete: (
  //             <Tooltip title={value?.disable ? "move to Active" : "delete"}>
  //               <IconButton
  //                 aria-label="action_edit"
  //                 // disabled={value?.disable}
  //                 onClick={() => {
  //                   handleBinSwitch(value?._id);
  //                 }}
  //               >
  //                 {value?.disable ? (
  //                   <Input
  //                     sx={({ palette: { dark, white, info } }) => ({
  //                       color: darkMode ? info.main : dark.main,
  //                     })}
  //                   />
  //                 ) : (
  //                   <Delete
  //                     sx={({ palette: { dark, white, info } }) => ({
  //                       color: darkMode ? info.main : dark.main,
  //                     })}
  //                   />
  //                 )}
  //               </IconButton>
  //             </Tooltip>
  //           ),
  //           action: (
  //             <MDBox
  //               sx={{
  //                 display: "flex",
  //                 justifyContent: "center",
  //                 flexDirection: { sx: "column", xs: "column", md: "row", xl: "row" },
  //                 alignItems: "center",
  //                 gap: 2,
  //               }}
  //             >
  //               <IconButton
  //                 aria-label="action-edit"
  //                 onClick={() => {
  //                   setViewData(value), setIsOpenUpdate2(true), setIsUpdate(true);
  //                 }}
  //               >
  //                 <Edit
  //                   sx={({ palette: { dark, white, info } }) => ({
  //                     color: darkMode ? white.main : info.main,
  //                   })}
  //                 />
  //               </IconButton>
  //             </MDBox>
  //           ),
  //         }));
  //       setRowsData(temprows);
  //     } else {
  //       setRowsData([]);
  //     }
  //   }, [isId, category]);

  // useEffect(() => {
  //     if (isValue ) {
  //       const temprows =
  //       isValue &&
  //         isValue?.at(0)?.subCategory &&
  //         isValue?.at(0)?.subCategory?.map((value, index) => ({
  //           no: (
  //             <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
  //               {index + 1}
  //             </MDTypography>
  //           ),
  //           name: (
  //             <MDTypography
  //               display="block"
  //               variant="button"
  //               fontWeight="medium"
  //               ml={1}
  //               lineHeight={1}
  //               // wordBreak=" break-all"
  //               style={{
  //                 maxWidth: "250px",
  //                 lineHeight: "20px",
  //                 display: "-webkit-box",
  //                 WebkitBoxOrient: "vertical",
  //                 WebkitLineClamp: 2,
  //                 overflow: "hidden",
  //                 textOverflow: "ellipsis",
  //               }}
  //             >
  //               {value?.name}
  //               {/* {console.log(value?.name, "value?.name")} */}
  //             </MDTypography>
  //           ),
  //           image: (
  //             <MDBox sx={{ height: 40, width: 40 }}>
  //               <img
  //                 src={`${process.env.REACT_APP_URI}/${value?.icon}`}
  //                 alt={"img"}
  //                 // onError={(e) => {
  //                 //   (e.onError = null),
  //                 //     (e.target.src = require("../../assets/images/bg-profile.jpeg"));
  //                 // }}
  //                 style={{ width: "100%", height: "100%", borderRadius: "50%" }}
  //               />
  //             </MDBox>
  //           ),
  //           view: (
  //             <IconButton
  //               aria-label="action_edit"
  //               onClick={() => {
  //                 setViewData(value);
  //                 setIsOpenView(true);
  //               }}
  //             >
  //               <Visibility
  //                 sx={({ palette: { dark, white, info } }) => ({
  //                   color: darkMode ? info.main : dark.main,
  //                 })}
  //               />
  //             </IconButton>
  //           ),

  //           // disable: (
  //           //   <Switch
  //           //     value={value?.disable}
  //           //     checked={value?.disable}
  //           //     color={"info"}
  //           //     onChange={(e) => handleChangeSwitch(value?._id)}
  //           //     // onChange={() => log("showInHome")console.}
  //           //     inputProps={{ "aria-label": "controlled" }}
  //           //   />
  //           // ),

  //           delete: (
  //             <Tooltip title={value?.disable ? "move to Active" : "delete"}>
  //               <IconButton
  //                 aria-label="action_edit"
  //                 // disabled={value?.disable}
  //                 onClick={() => {
  //                   handleBinSwitch(value?._id);
  //                 }}
  //               >
  //                 {value?.disable ? (
  //                   <Input
  //                     sx={({ palette: { dark, white, info } }) => ({
  //                       color: darkMode ? info.main : dark.main,
  //                     })}
  //                   />
  //                 ) : (
  //                   <Delete
  //                     sx={({ palette: { dark, white, info } }) => ({
  //                       color: darkMode ? info.main : dark.main,
  //                     })}
  //                   />
  //                 )}
  //               </IconButton>
  //             </Tooltip>
  //           ),
  //           action: (
  //             <MDBox
  //               sx={{
  //                 display: "flex",
  //                 justifyContent: "center",
  //                 flexDirection: { sx: "column", xs: "column", md: "row", xl: "row" },
  //                 alignItems: "center",
  //                 gap: 2,
  //               }}
  //             >
  //               <IconButton
  //                 aria-label="action-edit"
  //                 onClick={() => {
  //                   setViewData(value), setIsOpenUpdate2(true), setIsUpdate(true);
  //                 }}
  //               >
  //                 <Edit
  //                   sx={({ palette: { dark, white, info } }) => ({
  //                     color: darkMode ? white.main : info.main,
  //                   })}
  //                 />
  //               </IconButton>
  //             </MDBox>
  //           ),
  //         }));
  //       setRowsData(temprows);
  //     } else {
  //       setRowsData([]);
  //     }
  //   }, [category]);

  useEffect(() => {
    const temprows =
      isValue?.subCategory &&
      isValue?.subCategory?.at(0) &&
      isValue?.subCategory?.map((value, index) => ({
        no: (
          <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
            {index + 1}
          </MDTypography>
        ),
        name: (
          <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
            {value?.name}
          </MDTypography>
        ),
        image: (
          <MDAvatar
            src={`${process.env.REACT_APP_URI}/${value?.icon}`}
            name={value?.name}
            size="sm"
            variant="rounded"
          />
        ),
        view: (
          <IconButton
            aria-label="action_edit"
            onClick={() => {
              setViewData(value);
              setIsOpenView(true);
            }}
          >
            <Visibility
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? info.main : dark.main,
              })}
            />
          </IconButton>
        ),
        delete: (
          <Tooltip title={value?.disable ? "move to Active" : "delete"}>
            <IconButton
              aria-label="action_edit"
              // disabled={value?.disable}
              onClick={() => {
                handleBinSwitch(value?._id);
              }}
            >
              {value?.disable ? (
                <Input
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              ) : (
                <Delete
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              )}
            </IconButton>
          </Tooltip>
        ),
        action: (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: { sx: "column", xs: "column", md: "row", xl: "row" },
              alignItems: "center",
              gap: 2,
            }}
          >
            <IconButton
              aria-label="action-edit"
              onClick={() => {
                setViewData(value), setIsOpenUpdate2(true), setIsUpdate(true);
              }}
            >
              <Edit
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white.main : info.main,
                })}
              />
            </IconButton>
          </MDBox>
        ),
        // action: (
        //   <Switch
        //     checked={value?.showInHome}
        //     onChange={() => console.log("showInHome")}
        //     inputProps={{ "aria-label": "controlled" }}
        //   />
        // ),
      }));
    // console.log(temprows, "temprows");
    setRowssubData(temprows);
  }, [isValue, isId]);

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const pColumns = [
    { Header: "S.No", accessor: "no" },
    { Header: "Image", accessor: "image", width: "200px" },
    { Header: "Name", accessor: "name", width: "200px" },
    { Header: "View", accessor: "view", width: "200px" },
    { Header: "delete", accessor: "delete", width: "200px" },
    { Header: "action", accessor: "action", width: "200px" },
  ];

  const [file, setFile] = useState();
  const [profile, setProfile] = useState({
    categoryName: "",
    uploadPic: "",
    bannerImage: "",
    bannerVideo: "",
    cityId: [],
    categoryId: "",
    category__Id: "",
    isShow: "",
    serverBannerImage: null,
  });
  // console.log(profile);
  useEffect(() => {
    if (!isOpen && !isOpen2 && !isOpenUpdate && !isOpenUpdate2)
      setProfile({
        categoryName: "",
        uploadPic: "",
        bannerImage: "",
        bannerVideo: "",
        cityId: [],
        categoryId: "",
        category__Id: "",
        isShow: "",
        serverBannerImage: null,
      });
  }, [isOpen, isOpen2, isOpenUpdate, isOpenUpdate2]);

  const handleBinSwitch = (value) => {
    dispatch(
      updateCategory({
        url: `${process.env.REACT_APP_APII}/eCommerce/disableCategory/${value}/${admin}`,
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        dispatch(
          getGlobalCategory(
            `eCommerce/getAllCategoryWithPcategory/${admin}?disable=${
              filter === false ? false : filter || ""
            }&page=${pagess || ""}`
          )
        );
      }
    });
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card id="delete-account">
            <MDBox
              p={1.5}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                flexDirection: { xs: "column", sm: "column", md: "row", xl: "row" },
                gap: 2,
              }}
            >
              <MDButton
                variant="gradient"
                disabled={Loading}
                sx={({ palette: { dark, white, info } }) => ({
                  color: white.main,
                  backgroundColor: darkMode ? info.main : dark.main,
                  "&:hover": {
                    color: white.main,
                    backgroundColor: darkMode ? info.main : dark.main,
                  },
                })}
                onClick={() => {
                  setIsOpenUpdate(true);
                  setIsPUpdate(false);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp; Create Category
              </MDButton>
              <MDButton
                disabled={Loading}
                variant="gradient"
                sx={({ palette: { dark, white, info } }) => ({
                  color: white.main,
                  backgroundColor: darkMode ? info.main : dark.main,
                  "&:hover": {
                    color: white.main,
                    backgroundColor: darkMode ? info.main : dark.main,
                  },
                })}
                onClick={() => {
                  setIsOpenUpdate2(true);
                  setIsUpdate(false);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp; Create subCategory
              </MDButton>
            </MDBox>{" "}
          </Card>
        </MDBox>
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Category Table{" "}
              </MDTypography>
            </MDBox>

            <MDBox py={3} display="flex" justifyContent="flex-end">
              <MDBox width="23%" display="flex" flexDirection="column" padding="2%">
                <MDTypography variant="button">Filter By Visibility</MDTypography>
                <Select
                  disabled={Loading}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={filter}
                  onChange={(e) => {
                    setPagess(1);
                    setFilter(e.target.value);
                  }}
                  sx={({ palette: { dark, white, info } }) => ({
                    width: "100%",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "&	.MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  {disableFilter?.map((ele, i) => (
                    <MenuItem component="option" key={i} value={ele?._id}>
                      {ele?.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            </MDBox>

            <MDBox pt={3} width={"100%"}>
              {Loading ? (
                <SkLoading />
              ) : category && category?.at(0) ? (
                category.map((value, index) => (
                  <>
                    <Accordion
                      key={index}
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                      onClick={() => {
                        setIsId(value?._id);
                        setIsValue(value);
                      }}
                      py={1.5}
                      px={3}
                      sx={{
                        "&.MuiPaper-root": {
                          backgroundColor: "transparent.main",
                        },
                      }}
                      style={{
                        "&.MuiPaper-root": {
                          backgroundColor: "transparent",
                          width: "100% !important",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMore
                            sx={({ palette: { dark, white, info } }) => ({
                              color: darkMode ? white.main : info.main,
                              textAlign: "left",
                              mr: 2,
                            })}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row-reverse",
                          width: "100%",
                        }}
                      >
                        <MDBox
                          id="shubham"
                          sx={({ breakpoints }) => ({
                            display: "flex",
                            alignItems: "space-between",
                            justifyContent: "space-between",
                            // gap: 1,
                            flexGrow: 1,
                            width: "100%",
                            // flexDirection: { sx: "column", sm: "column", md: "row", xl: "row" },

                            [breakpoints.up("xs")]: {
                              flexDirection: "column",

                              alignItems: "flex-start",
                              justifyContent: "space-between",
                              // gap: 1,
                            },
                            [breakpoints.up("md")]: {
                              flexDirection: "row",

                              alignItems: "flex-start",
                              justifyContent: "space-between",
                              // gap: 1,
                            },
                          })}
                        >
                          <MDBox
                            display="flex"
                            alignItems="center"
                            lineHeight={1}
                            sx={{ mr: 1, width: "30%" }}
                          >
                            <MDBox sx={{ height: 40, width: 40 }}>
                              <img
                                src={`${process.env.REACT_APP_URI}/${value?.icon}`}
                                alt={"img"}
                                onError={(e) => {
                                  (e.onError = null),
                                    (e.target.src = require("../../assets/images/bg-profile.png"));
                                }}
                                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                              />
                            </MDBox>
                            <MDTypography
                              display="block"
                              variant="button"
                              fontWeight="medium"
                              ml={1}
                              lineHeight={1}
                              style={{
                                maxWidth: "250px",
                                lineHeight: "20px",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {value?.name}
                            </MDTypography>
                          </MDBox>
                          <MDBox
                            display="flex"
                            alignItems="center"
                            lineHeight={1}
                            zIndex={10}
                            sx={{ width: "20%" }}
                          >
                            <MDTypography
                              display="block"
                              variant="button"
                              fontWeight="medium"
                              ml={1}
                              lineHeight={1}
                            >
                              View
                            </MDTypography>
                            <IconButton
                              aria-label="action_edit"
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewData(value);
                                setIsOpenView(true);
                              }}
                            >
                              <Visibility
                                sx={({ palette: { dark, white, info } }) => ({
                                  color: darkMode ? info.main : dark.main,
                                })}
                              />
                            </IconButton>
                          </MDBox>

                          <MDBox
                            display="flex"
                            alignItems="center"
                            lineHeight={1}
                            zIndex={10}
                            sx={{ width: "20%" }}
                          >
                            <Tooltip title={value?.disable ? "move to Active" : "delete"}>
                              <IconButton
                                aria-label="action_edit"
                                // disabled={value?.disable}
                                onClick={() => {
                                  handleBinSwitch(value?._id);
                                }}
                              >
                                {value?.disable ? (
                                  <Input
                                    sx={({ palette: { dark, white, info } }) => ({
                                      color: darkMode ? info.main : dark.main,
                                    })}
                                  />
                                ) : (
                                  <Delete
                                    sx={({ palette: { dark, white, info } }) => ({
                                      color: darkMode ? info.main : dark.main,
                                    })}
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                          </MDBox>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              // flexDirection: { sx: "column", xs: "column", md: "row", xl: "row" },
                              alignItems: "center",
                              gap: 2,
                              width: "20%",
                            }}
                          >
                            <MDTypography
                              display="block"
                              variant="button"
                              fontWeight="medium"
                              ml={1}
                              lineHeight={1}
                            >
                              Edit
                            </MDTypography>
                            <IconButton
                              aria-label="action-edit"
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsOpenUpdate(true);
                                setViewData(value);
                                setIsPUpdate(true);
                                // handleUpdateCategory(value);
                              }}
                            >
                              <Edit
                                sx={({ palette: { dark, white, info } }) => ({
                                  color: darkMode ? white.main : info.main,
                                })}
                              />
                            </IconButton>
                          </MDBox>
                        </MDBox>
                      </AccordionSummary>
                      <AccordionDetails>
                        {IsLoading ? (
                          <SkLoading />
                        ) : expanded === `panel${index}` &&
                          value?.subCategory &&
                          value?.subCategory.length &&
                          value?.subCategory ? (
                          <MDBox sx={{ textAlign: "center" }}>
                            <MDTypography variant="button" fontWeight="medium" color="info">
                              SubCategory details
                            </MDTypography>
                            <DataTable
                              table={{
                                columns: pColumns,
                                rows: rowsSubData || [],
                              }}
                              isSorted={false}
                              entriesPerPage={false}
                              showTotalEntries={false}
                              noEndBorder
                            />
                          </MDBox>
                        ) : (
                          <MDTypography
                            display="block"
                            variant="button"
                            fontWeight="medium"
                            ml={1}
                            lineHeight={1}
                          >
                            there are no subCategory
                          </MDTypography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </>
                ))
              ) : (
                <MDTypography
                  display="block"
                  variant="h6"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  align="center"
                >
                   Category Is Not Founds ...
                </MDTypography>
              )}
              <MDBox
                sx={{
                  mt: 5,
                  mb: 2,
                  // minHeigth: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack spacing={2} direction={"row"}>
                  <Pagination
                    sx={({ palette: { dark, white, info } }) => ({
                      "&.MuiPaginationItem-text": {
                        color: darkMode ? white.main : dark.main,
                      },
                      "&	.MuiPaginationItem-icon": {
                        color: darkMode ? white.main : dark.main,
                      },
                      "&		.MuiPaginationItem-textInfo": {
                        color: darkMode ? white.main : dark.main,
                      },
                    })}
                    color="info"
                    variant="text"
                    count={isPages}
                    page={pagess}
                    onChange={(e, value) => setPagess(value)}
                  />
                </Stack>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpenView}
        unShow={setIsOpenView}
        width={{ sx: "100%", md: "50%", xl: "50%", sm: "100%" }}
        height={"80%"}
        padding={3}
        overflowY={true}
      >
        <SingleCategory viewData={viewData} />
      </SkModal>

      <SkModal
        show={isOpenUpdate}
        unShow={setIsOpenUpdate}
        width={{ sx: "100%", md: "50%", xl: "50%", sm: "100%" }}
        height={"80%"}
        padding={3}
        overflowY={true}
      >
        <PcatogeroyUpdate
          viewData={viewData}
          isPUpdates={isPUpdates}
          setIsOpenUpdate={setIsOpenUpdate}
          filter={filter}
        />
      </SkModal>
      <SkModal
        show={isOpenUpdate2}
        unShow={setIsOpenUpdate2}
        width={{ sx: "100%", md: "50%", xl: "50%", sm: "100%" }}
        height={"80%"}
        overflowY={true}
        padding={3}
      >
        <SubcategoryUpdate
          viewData={viewData}
          isUpdates={isUpdates}
          setIsOpenUpdate2={setIsOpenUpdate2}
          filter={filter}
        />
      </SkModal>
      <SkConfirm
        dialogTitle={"Delete"}
        dialogContent={"Are you sure you want to delete this category?"}
        open={isOpenDialog}
        setOpen={setIsOpenDialog}
        dialogAction={
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3,

              px: 3,
            }}
          >
            <MDButton
              variant="gradient"
              color="info"
              sx={{ width: "50%" }}
              onClick={() => {
                setIsOpenDialog((Preview) => ({
                  ...Preview,
                  open: false,
                  isId: null,
                }));
              }}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              sx={{ width: "50%" }}
              color="error"
              onClick={() => {
                dispatch(
                  deleteCategory({
                    url: `${process.env.REACT_APP_API}/deleteCategory/${isOpenDialog?.isId}/${admin}`,
                  })
                ).then((data) => {
                  dispatch(
                    handleAlert({
                      isOpen: true,
                      type: `${data?.payload?.success ? "success" : "error"}`,
                      msg: data?.payload?.message,
                    })
                  );
                  if (data?.payload?.success) {
                    dispatch(getGlobalCategory(`/getAllNullParantCategory`));
                    dispatch(
                      getSubGlobalCategory(`/getAllCategoryByPCategoryId/${isOpenDialog?.isId}`)
                    );
                    setIsOpenDialog((Preview) => ({
                      ...Preview,
                      open: false,
                    }));
                  }
                  setIsId(isOpenDialog?.isId);
                });
              }}
            >
              yes
            </MDButton>
          </MDBox>
        }
      />
    </>
  );
};

export default Category;
