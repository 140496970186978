import http from "Utils/api";
import axios from "axios";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export const getAllQna = createAsyncThunk("getAllQna", async ({ url }) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getOneQna = createAsyncThunk("getOneQna", async ({ url }) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getGlobalAllQNA = createAsyncThunk("getGlobalAllQNA", async ({ url }) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getAllSupportMessage = createAsyncThunk("getAllSupportMessage", async ({ url }) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getAllSupportUsers = createAsyncThunk("getAllSupportUsers", async ({ url }) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getAllSupportPartner = createAsyncThunk("getAllSupportPartner", async ({ url }) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getAllSupportCustomer = createAsyncThunk("getAllSupportCustomer", async ({ url }) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const updateQNA = createAsyncThunk("updateQNA", async ({ url, data }) => {
  try {
    const res = await axios.put(url, data, {
      headers: {
        // "Content-Type": "multipart/form-data",
        // Accept: "application/json",
        authorization: localStorage.getItem("token"),
      },
    });
    // console.log(res.data)
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const createMessage = createAsyncThunk("createMessage", async ({ url, data }) => {
  try {
    const res = await http.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        // authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
const supportandQnaSlice = createSlice({
  name: "supportAndQna",
  initialState: {
    chatData: [],
    QNAData: [],
    message: [],
    allUsers: [],
    allPartners: [],
    allCustomers: [],
    SingleQNAData: null,
    Loading: false,
    isLoading: false,
    cLoading: false,
    pLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllQna.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllQna.fulfilled, (state, action) => {
        state.isLoading = false;
        state.QNAData = action.payload?.data;
        // console.log(action.payload?.data);
      })
      .addCase(getAllQna.rejected, (state, action) => {
        state.isLoading = false;

        // console.log(action.payload?.data);
      })
      .addCase(getAllSupportUsers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllSupportUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allUsers = action.payload?.data;
        // console.log(action.payload?.data);
      })
      .addCase(getAllSupportUsers.rejected, (state, action) => {
        state.isLoading = false;

        // console.log(action.payload?.data);
      })
      .addCase(getAllSupportCustomer.pending, (state, action) => {
        state.cLoading = true;
      })
      .addCase(getAllSupportCustomer.fulfilled, (state, action) => {
        state.cLoading = false;
        state.allCustomers = action.payload?.data;
        // console.log(action.payload?.data);
      })
      .addCase(getAllSupportCustomer.rejected, (state, action) => {
        state.cLoading = false;

        // console.log(action.payload?.data);
      })
      .addCase(getAllSupportPartner.pending, (state, action) => {
        state.pLoading = true;
      })
      .addCase(getAllSupportPartner.fulfilled, (state, action) => {
        state.pLoading = false;
        state.allPartners = action.payload?.data;
        // console.log(action.payload?.data);
      })
      .addCase(getAllSupportPartner.rejected, (state, action) => {
        state.pLoading = false;

        // console.log(action.payload?.data);
      })
      .addCase(getOneQna.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getOneQna.fulfilled, (state, action) => {
        state.Loading = false;
        state.SingleQNAData = action.payload?.data;
        // console.log(action.payload?.data);
      })
      .addCase(getOneQna.rejected, (state, action) => {
        state.Loading = false;

        // console.log(action.payload?.data);
      })
      .addCase(getGlobalAllQNA.fulfilled, (state, action) => {
        state.isLoading = false;
        state.QNAData = action.payload?.data;
        // console.log(action.payload?.data);
      })
      .addCase(getAllSupportMessage.fulfilled, (state, action) => {
        state.Loading = false;
        state.message = action.payload?.data;
        // console.log(action.payload?.data);
      });
  },
});
export default supportandQnaSlice.reducer;
