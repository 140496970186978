import * as XLSX from "xlsx";
import http from "Utils/api2";
import { formattedDateServer } from "Utils/dateFunc";
import { SkPrice } from "Utils/dateFunc";

// Function to fetch data from a specific page
const fetchData = async (url, page) => {
  try {
    const res = await http.get(`${url}&page=${page}`);
    return res.data?.data;
  } catch (error) {
    return { data: [] };
  }
  // try {
  //   const response = await fetch(`${url}&page=${page}`);
  //   const result = await response.json();
  //   return result.data; // Assuming the data is stored in result.data
  // } catch (error) {
  //   console.error("Error fetching data:", error);
  //   return [];
  // }
};

export const generateExcelWithAllOrderData = async ({ url, totalPages, loading, ecom }) => {
  // Get the total number of pages
  // const totalPages = await getTotalPages();

  // Accumulate data from all pages
  loading(true);
  try {
    // Array of promises for each page fetch
    const pagePromises = [];

    for (let page = 1; page <= totalPages; page++) {
      pagePromises.push(fetchData(url, page));
    }

    // Fetch all pages concurrently using Promise.all
    const isData = await Promise.all(pagePromises);

    // Combine the results from each page
    const combinedData = isData.flat(); // Or use .concat() if older versions of JS

    // console.log(combinedData, "ASdfs");
    // allData = combinedData;

    // Now generate the PDF with the accumulated data
    exportToExcel(combinedData, ecom);
  } catch (error) {
    console.log(error, "pdf");
  } finally {
    loading(false);
  }
};

const exportToExcel = (allListData, ecom) => {
  const data = allListData?.map((ele, i) => ({
    "S.No": i + 1,
    "User Name": `${ele?.customerId?.fullName}`,
    "Order Id": `${ele?._id}`,
    "Order Status": `${ele?.status}`,
    "Payment Method": `${ele?.paymentMethod}`,
    "Order Total Price": `${ele?.orderTotal}`,
    ...(ecom ? {} : { "Service Date": `${ele?.date || "N/A"}` }),
    "Place Order": `${new Date(ele?.createdAt).toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      // second: "numeric",
    })}`,
    "Last Updated Order": `${new Date(ele?.updatedAt).toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      // second: "numeric",
    })}`,
    ...(ecom
      ? {}
      : {
          "Partner Name": ele?.partnerId?.fullName
            ? `${ele?.partnerId?.fullName}, ID : ${ele?.partnerId?._id}`
            : "Partner not assigned",
        }),
    ...(ecom
      ? {}
      : {
          "Partner phoneNumber": ele?.partnerId?.phoneNumber
            ? `${ele?.partnerId?.phoneNumber}`
            : "Partner not assigned",
        }),
    address: `${ele?.address?.address}`,
    apartment: `${ele?.address?.apartment}`,
    landmark: `${ele?.address?.landmark}`,
    city: `${ele?.address?.city}`,
    state: `${ele?.address?.state}`,
    country: `${ele?.address?.country}`,
    pinCode: `${ele?.address?.pinCode}`,
    Products: `${
      ele?.product?.map((x, i) => `${i + 1}. ${x?.productId?.title || "-"}`).join(", ") ||
      "No products"
    }`,
  }));
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    ecom ? "E-Commerce Order List" : "Service Order List"
  );

  // Set column widths
  worksheet["!cols"] = [
    { wpx: 50 }, // S.No column
    { wpx: 200 }, // User Name column
    { wpx: 200 }, // Order Id column
    { wpx: 150 }, // Order Status column
    { wpx: 150 }, // Payment Method column
    { wpx: 100 }, // Order Total column
    { wpx: 150 }, // Place Order column
    { wpx: 150 }, // Place Order column
    { wpx: 150 }, // Place Order column
    { wpx: 150 }, // Place Order column
    { wpx: 150 }, // Place Order column
    { wpx: 150 }, // Place Order column
    { wpx: 150 }, // Place Order column
    { wpx: 150 }, // Place Order column
    { wpx: 150 }, // Place Order column

    !ecom && { wpx: 150 }, // Place Order column
    !ecom && { wpx: 150 }, // Place Order column
    !ecom && { wpx: 150 }, // Place Order column
    !ecom && { wpx: 150 }, // Place Order column
  ];

  // Get the range of the worksheet to ensure all cells exist
  const range = XLSX.utils.decode_range(worksheet["!ref"]);

  // Apply styles to header cells if they exist
  if (range.s.r === 0) {
    const headerStyle = {
      fill: { fgColor: { rgb: "FFFFA500" } }, // Orange background color
      font: { color: { rgb: "FFFFFFFF" }, bold: true, sz: 12 }, // White font color, bold, and size 12
    };
    worksheet["A1"].s = headerStyle; // S.No header
    worksheet["B1"].s = headerStyle; // User Name header
    worksheet["C1"].s = headerStyle; // Order Id header
    worksheet["D1"].s = headerStyle; // Order Status header
    worksheet["E1"].s = headerStyle; // Payment Method header
    worksheet["F1"].s = headerStyle; // Order Total header
    worksheet["G1"].s = headerStyle; // Place Order header
    worksheet["H1"].s = headerStyle; // Shipping Address header
    worksheet["I1"].s = headerStyle; // Billing Address header
    worksheet["J1"].s = headerStyle; // Phone Number header
    worksheet["K1"].s = headerStyle; // Email Address header
    worksheet["L1"].s = headerStyle; // Shipping Method header
    worksheet["M1"].s = headerStyle; // Delivery Date header
    worksheet["N1"].s = headerStyle; // Discount header
    worksheet["O1"].s = headerStyle; // Tax header   worksheet["P1"].s = headerStyle; // Final Total header
    !ecom && (worksheet["P1"].s = headerStyle); // Final Total header
    !ecom && (worksheet["Q1"].s = headerStyle); // Final Total header
    !ecom && (worksheet["R1"].s = headerStyle); // Final Total header
    !ecom && (worksheet["S1"].s = headerStyle); // Final Total header
  }

  // Save to file
  XLSX.writeFile(workbook, ecom ? "E-Commerce Order List.xlsx" : "Service Order List.xlsx");
};
