import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import MDInput from "components/MDInput";
import AstrieskIcon from "components/AstrieskIcon";
import { Card } from "@mui/material";
import { useMaterialUIController } from "context";
import { handleAlert } from "redux/festures/alertSlice";
import { updateQNA } from "redux/festures/supportandQnaSlice";
import { getGlobalAllQNA } from "redux/festures/supportandQnaSlice";

const Form = ({ setIsOpen }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();
  const { Loading, SingleQNAData } = useSelector((state) => state.isSupport);
  const [taxData, setTaxData] = useState({
    question: "",
    answer: "",
  });
  useEffect(() => {
    if (SingleQNAData) {
      setTaxData((prev) => ({
        ...prev,
        question: SingleQNAData?.question,
        answer: SingleQNAData?.answer,
      }));
    } else {
      setTaxData((prev) => ({
        ...prev,
        question: "",
        answer: "",
      }));
    }
  }, [SingleQNAData]);
  const handleForm = (e) => {
    const { name, value } = e.target;
    setTaxData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateQNA({
        url: `${process.env.REACT_APP_APII}/updateQuestion/${SingleQNAData?._id}`,
        data: taxData,
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        setIsOpen(false);

        setTaxData((prev) => ({
          ...prev,
          question: "",
          answer: "",
        }));
        dispatch(getGlobalAllQNA({ url: "getAllQuestion" }));
      }
    });
  };
  return Loading ? (
    <SkLoading />
  ) : (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 5,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            {`Update Coupon's `}
          </MDTypography>
        </Card>
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            p: 3,
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            gap: 3,
            width: "100%",
          })}
          component="form"
          role="form"
            onSubmit={handleFormSubmit}
        >
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Question <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Question"
              fullWidth
              name="question"
              value={taxData?.question}
              onChange={handleForm}
              multiline
              rows={4}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Answer <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Answer"
              fullWidth
              name="answer"
              value={taxData?.answer}
              onChange={handleForm}
              multiline
              rows={4}
            />
          </MDBox>
          <MDBox
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              textAlign: "center",
              display: "flex",
            }}
          >
            {" "}
            <MDButton color={"info"} verdant={"gradient"} type={"submit"}>
              {`Update Tax`}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default Form;
Form.propTypes = {
  setIsOpen: PropTypes.any,
};
