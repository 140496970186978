import { AppBar, Box, Card, Grid, Icon, IconButton, Tab, Tabs, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import breakpoints from "assets/theme/base/breakpoints";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import {
  AssignmentReturnOutlined,
  Close,
  Edit,
  Gavel,
  Info,
  LowPriority,
  PhotoCamera,
  Policy,
  SelfImprovementOutlined,
  ShoppingBag,
  VerticalAlignBottomOutlined,
} from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import SkLoading from "components/SkLoading";
import MDBadge from "components/MDBadge";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
import Skeditor from "components/SKeditor";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import { skCompany } from "redux/festures/isCompany";
import MDAvatar from "components/MDAvatar";
import ImagePicker from "components/ApnaUploader";
// import { updateCompany } from "redux/festures/isCompany";
import { handleAlert } from "redux/festures/alertSlice";
import axios from "axios";
import MDInput from "components/MDInput";
// import AttributeInput from "components/ApnaSelect/Attribute";
import SkAutoCompleteSingle from "components/ApnaSelect/SkSingleAuto";
// import { isUpdateCompany } from "redux/festures/isCompany";
// import { EditorState,  } from "draft-js";

const BasicTabs = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // const { Loading, companyData } = useSelector((data) => ({ ...data?.isCompany }));\
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const [isTermsData, setIsTermsData] = useState(EditorState.createEmpty());
  const [isPolicyData, setPolicyData] = useState(EditorState.createEmpty());

  const [isReturnData, setIsReturn] = useState(EditorState.createEmpty());
  const [isRefundData, setIsRefundData] = useState(EditorState.createEmpty());
  const [isShoppingData, setIsShoppingData] = useState(EditorState.createEmpty());
  const [isFooterAbout, setIsFooterAbout] = useState(EditorState.createEmpty());

  const [file, setFile] = useState("");
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(skCompany({ url: `/getCompanyByAdmin/${admin}` }));
  }, []);
  const { Loading, companyData } = useSelector((data) => ({ ...data?.isCompany }));
  // console.log(companyData)
  const isUpdateCompany = async ({ data, content }) => {
    const formData = new FormData();
    formData.append(content, data);
    // console.log(...formData)
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_APII}/update/company/${admin}`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(
        handleAlert({
          isOpen: true,
          type: response?.data?.success ? "success" : "error",
          msg: response?.data?.message,
        })
      );
      dispatch(skCompany({ url: `/getCompanyByAdmin/${admin}` }));
      // console.log(response.data, "response");
      // Handle success
    } catch (error) {
      console.log(error, "responseERROR");
      // Handle error
    }
  };

  useEffect(() => {
    if (companyData) {
      const termsData = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(companyData?.term_condition).contentBlocks,
          convertFromHTML(companyData?.term_condition).entityMap
        )
      );
      const policyData = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(companyData?.privacy_policy).contentBlocks,
          convertFromHTML(companyData?.privacy_policy).entityMap
        )
      );
      const returnData = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(companyData?.return_policy).contentBlocks,
          convertFromHTML(companyData?.return_policy).entityMap
        )
      );
      const refundData = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(companyData?.refund_Policy).contentBlocks,
          convertFromHTML(companyData?.refund_Policy).entityMap
        )
      );
      const shoppingData = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(companyData?.shippingAndDelivery).contentBlocks,
          convertFromHTML(companyData?.shippingAndDelivery).entityMap
        )
      );
      setIsTermsData(termsData);
      setPolicyData(policyData);
      setIsReturn(returnData);
      setIsRefundData(refundData);
      setIsShoppingData(shoppingData);
    }
  }, [companyData]);
  // console.log(isTermsData, "isTermsData");
  // console.log(isPolicyData, "isPolicyData");
  // console.log(isReturnData, "isReturnData");
  const handleProfilePic = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setBanerImage(e.target.files && e.target.files.length ? e.target.files[0] : "");
  };

  const isTerms = (
    <Skeditor
      editorState={isTermsData}
      setEditorState={setIsTermsData}
      placeholder={"terms and condition"}
      initialContent={companyData && companyData?.term_condition}
      content={"term_condition"}
    />
  );
  const isPolicy = (
    <Skeditor
      editorState={isPolicyData}
      setEditorState={setPolicyData}
      placeholder={"privacy policy"}
      initialContent={companyData && companyData?.privacy_policy}
      content={"privacy_policy"}
    />
  );
  const isReturn = (
    <Skeditor
      editorState={isReturnData}
      setEditorState={setIsReturn}
      placeholder={"return and refund"}
      initialContent={companyData && companyData?.return_policy}
      content={"return_policy"}
    />
  );
  const isAbout = (
    <Skeditor
      editorState={isFooterAbout}
      setEditorState={setIsFooterAbout}
      placeholder={"About Us"}
      initialContent={companyData && companyData?.about_us}
      content={"about_us"}
    />
  );
  const isRefund = (
    <Skeditor
      editorState={isRefundData}
      setEditorState={setIsRefundData}
      placeholder={"Refund Policy"}
      initialContent={companyData && companyData?.refund_Policy}
      content={"refund_Policy"}
    />
  );
  const isShopping = (
    <Skeditor
      editorState={isShoppingData}
      setEditorState={setIsShoppingData}
      placeholder={"Shipping And Delivery"}
      initialContent={companyData && companyData?.shippingAndDelivery}
      content={"shippingAndDelivery"}
    />
  );

  // {console.log(companyData,"companyData")}
  const tabsName = [
    {
      name: "Term_Condition",
      value: "term_condition",
      url: `/product/productFilter/}`,
      icon: <Gavel />,
      pagination: true,
      isProp: isTerms,
    },
    {
      name: "Privacy_Policy ",
      value: "privacy_policy ",
      icon: <Policy />,
      //   url: `${process.env.REACT_APP_API}/lens/getAllLens`,

      pagination: true,
      isProp: isPolicy,
    },
    {
      name: "About ",
      value: "About ",
      icon: <Info />,
      //   url: `${process.env.REACT_APP_API}/lens/getAllLens`,

      pagination: true,
      isProp: isAbout,
    },
    {
      name: "Return Policy",
      value: "return_policy",
      icon: <AssignmentReturnOutlined />,
      //   url: `${process.env.REACT_APP_API}/lens/getAllLens`,

      pagination: true,
      isProp: isReturn,
    },
    {
      name: "Refund Policy",
      value: "refund_policy",
      icon: <LowPriority />,
      //   url: `${process.env.REACT_APP_API}/lens/getAllLens`,

      pagination: true,
      isProp: isRefund,
    },
    {
      name: "Shipping and Delivery",
      value: "shipping_and_Delivery",
      icon: <ShoppingBag />,
      //   url: `${process.env.REACT_APP_API}/lens/getAllLens`,

      pagination: true,
      isProp: isShopping,
    },
  ];
  const handlePageChange = (event, value) => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  };
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
       The event listener that's calling the handleTabsOrientation function when resizing the window.
      */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <div>
      <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
        <AppBar position="static">
          <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
            {tabsName.map((items, index) => (
              <Tab key={index} label={items?.name} icon={items?.icon} />
            ))}
          </Tabs>
        </AppBar>
      </Grid>
      {tabsName.map((items, index) => (
        <TabPanel value={tabValue} index={index} key={index}>
          <MDBox sx={{ p: 1, my: 3 }}>
            {Loading ? <SkLoading /> : companyData && items?.isProp}
          </MDBox>
        </TabPanel>
      ))}
    </div>
  );
};

export default BasicTabs;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
